import React from 'react';
import {
	useLocation,
	Route,
	Switch,
	Redirect,
	useHistory,
} from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import routes from '../routes.js';

const Auth = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (localStorage.getItem('token')) {
			history.replace('/admin/dashboard');
		} else {
			document.body.classList.add('bg-custom');
			return () => {
				document.body.classList.remove('bg-default');
			};
		}
	}, []);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/auth') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<div className='main-content bg-custom' ref={mainContent}>
				<div className='header py-7 py-lg-7'></div>
				{/* Page content */}
				<Container className='mt--8 pb-5'>
					<Row className='justify-content-center'>
						<Switch>
							{getRoutes(routes)}
							<Redirect from='*' to='/auth/login' />
						</Switch>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Auth;

import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import { getDashboardDataApi } from 'services/api';

import Breadcrumbs from '../components/Breadcrumbs';
const Dashboard = (props) => {
	const [dashboardData, setDashboardData] = useState(null);

	useEffect(() => {
		getDashboardDataApi().then((result) => {
			if (result) {
				setDashboardData(result);
			}
		});
	}, []);
	return (
		<>
			<Breadcrumbs breadcrumbName='Dashboard' />
			<div className='header bg-custom pb-7'>
				<Container fluid>
					<div className='header-body'>
						<Row>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Loan Apply
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanApply}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-green text-white rounded-circle shadow'>
													<i className='fas fa-briefcase' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total loan approved
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanApproved}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
													<i className='fas fa-thumbs-up' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Pending loan
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanPending}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
													<i className='fas fa-clock' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							{/* <Col lg='6' xl='3'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Work From Home User
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>49,65%</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
													<i className='fas fa-user-circle' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col> */}
						</Row>
						<Row className='mt-4'>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Earning Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanAmount}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Total Paid Amount
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>0</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col lg='6' xl='4'>
								<Card className='card-stats mb-4 mb-xl-0'>
									<CardBody>
										<Row>
											<div className='col'>
												<CardTitle
													tag='h5'
													className='text-uppercase text-muted mb-0'>
													Left To Pay
												</CardTitle>
												<span className='h2 font-weight-bold mb-0'>
													<NumberFormat
														value={dashboardData?.totalLoanAmount}
														displayType={'text'}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<Col className='col-auto'>
												<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
													<i className='fas fa-dollar-sign' />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Dashboard;

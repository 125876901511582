import React from 'react';

import { toast } from 'react-toastify';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from 'reactstrap';

import { setToken } from '../services/dataService';
import { loginAPI } from '../services/api';
import { useHistory } from 'react-router-dom';

const Login = () => {
	var email, password;
	const history = useHistory();
	const handleLogin = () => {
		if (email.value !== undefined && password.value !== undefined) {
			var loginObj = {
				email: email.value,
				password: password.value,
				isAdmin: true,
			};
			loginAPI(loginObj).then((result) => {
				if (result && result.result === 'fail') {
					toast.error(result.msg);
				} else {
					setToken(result.token);
					history.push('/admin/dashboard');
				}
			});
		} else {
		}
	};

	return (
		<>
			<Col lg='5' md='7'>
				<Card className='bg-secondary shadow border-0'>
					<CardHeader className='bg-transparent pb-4'>
						<div className='text-muted text-center mt-2 mb-3'>
							<img
								alt='...'
								width='170'
								height='90'
								src={require('../assets/img/brand/logo.png').default}
							/>
						</div>
					</CardHeader>
					<CardBody className='px-lg-4 py-lg-4'>
						<Form role='form'>
							<FormGroup className='mb-3'>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='fas fa-envelope' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										name='email'
										innerRef={(a) => {
											email = a;
										}}
										placeholder='Email'
										type='email'
										autoComplete='new-email'
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className='input-group-alternative'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='fas fa-lock' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										name='password'
										innerRef={(a) => {
											password = a;
										}}
										placeholder='Password'
										type='password'
										autoComplete='new-password'
									/>
								</InputGroup>
							</FormGroup>
							<div className='custom-control custom-control-alternative custom-checkbox'>
								<input
									className='custom-control-input'
									id=' customCheckLogin'
									type='checkbox'
								/>
								{/* <label
									className='custom-control-label'
									htmlFor=' customCheckLogin'>
									<span className='text-muted'>Remember me</span>
								</label> */}
							</div>
							<div className='text-center'>
								<Button
									onClick={handleLogin}
									className='my-4 btn-custom-green text-white'
									type='button'>
									Sign In
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
				{/* <Row className='mt-3'>
					<Col xs='6'>
						<a
							className='text-light'
							href='#pablo'
							onClick={(e) => e.preventDefault()}>
							<small>Forgot password?</small>
						</a>
					</Col>
					<Col className='text-right' xs='6'>
						<a
							className='text-light'
							href='#pablo'
							onClick={(e) => e.preventDefault()}>
							<small>Create new account</small>
						</a>
					</Col>
				</Row> */}
			</Col>
		</>
	);
};

export default Login;

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Container, Col, Row, Card, CardHeader, Button, Modal } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getLoanExcelData } from '../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultArray = [
    {
        value: null,
        label: 'Select',
    },
    {
        value: 'delete',
        label: 'Delete',
    },
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
];

const walletArray = [
    {
        value: null,
        label: 'Select',
    },
    {
        value: 'delete',
        label: 'Delete',
    },
];

const Tables = ({
    data,
    columns,
    tableTitle,
    createBtn = false,
    enableExport = false,
    showPaymentModal = false,
    currentPath = 'dashboard',
    selectDropDownArray = window.location.href.endsWith('/wallet') ? walletArray : defaultArray,
    defaultSortKey,
    defaultSortDirection,
    updateMultipleStatus,
}) => {
    let tableRef = React.createRef();
    const history = useHistory();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [selectionType, setSelectionType] = useState(null);
    const [show, setShow] = useState(false);
    const [confirmBtnTitle, setConfirmBtnTitle] = useState('');
    const [mainTitle, setMainTitle] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showCancel, SetShowCancel] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        /* onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		}, */
    });

    const { SearchBar } = Search;

    const indication = () => {
        return (
            <div className="text-muted">
                <p>No Records Found!</p>
            </div>
        );
    };

    const defaultSorted = [
        {
            dataField: defaultSortKey,
            order: defaultSortDirection,
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        onSelect: (row, isSelect, rowIndex, e) => {
            isSelect && setSelectedRows([...selectedRows, row]);
            if (isSelect) {
                setSelectedRows([...selectedRows, row]);
            } else {
                const removeUnSelected = selectedRows.filter((item) => {
                    return item.id !== row.id;
                });
                setSelectedRows(removeUnSelected);
            }
            setIsRowSelected(() => isSelect);
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setIsRowSelected(isSelect);
                setSelectedRows(rows);
            } else {
                setIsRowSelected(isSelect);
                setSelectedRows([]);
            }
        },
    };

    const clearSelection = () => {
        // console.log(tableRef.reset());
        Array.from(document.querySelectorAll('.selection-input-4')).forEach((el) => (el.checked = false));
    };

    const submitSelection = () => {
        if (selectionType && selectedRows.length > 0 && selectionType === 'delete') {
            setConfirmBtnTitle('Yes, delete it!');
            setMainTitle('Are you sure?');
            SetShowCancel(true);
        } else if (
            selectionType &&
            selectedRows.length > 0 &&
            (selectionType === 'active' || selectionType === 'inactive')
        ) {
            setConfirmBtnTitle('Yes, update status!');
            setMainTitle('Are you sure?');
            SetShowCancel(true);
        } else {
            if (selectedRows.length === 0) {
                setMainTitle('Please select at least one checkbox');
            } else {
                setMainTitle('Please provide action type');
            }
            setConfirmBtnTitle('Ok');
            SetShowCancel(false);
        }
        setShow(true);
        SweetAlertFun();
    };

    const confirmSelection = (event) => {
        setShow(false);
        if (selectionType === 'Select' || selectedRows.length === 0) return;
        const mappedSelectedRow = selectedRows.map((item) => item.id);
        updateMultipleStatus(mappedSelectedRow, selectionType);
    };

    const handleSelectionType = (e) => {
        setSelectionType(e.target.value);
    };

    const onDateChange = (e) => {
        if (e.target.name == 'fromDate') {
            setFromDate(e.target.value);
        }
        if (e.target.name == 'toDate') {
            setToDate(e.target.value);
        }
    };

    const goToWallet = (paymentMethod = '') => {
        history.push(`${currentPath}/create/${paymentMethod}`);
    };

    const getExport = () => {
        var XLSX = require('xlsx-js-style');
        var fields = [
            'firstName',
            'middleName',
            'lastName',
            'email',
            'phoneNo1',
            'phoneNo2',
            'address1',
            'city',
            'state',
            'pinCode',
            'dob',
            'ssn',
            'bankName',
            'accountType',
            'accountNo',
            'routingNo',
            'initialAmount',
            'months',
            'repaymentMonth',
            'loanType',
            'isPastLoan',
            'isPendingLoan',
            'isPendingBills',
            'createdAt',
        ];
        var fieldData = [];
        if (fromDate != '' && toDate != '') {
            getLoanExcelData(fromDate, toDate).then((res) => {
                for (let i = 0; i < res.data.length; i++) {
                    var obj = {};
                    for (let j = 0; j < fields.length; j++) {
                        if (res.data[i][fields[j]] != undefined) {
                            obj[fields[j]] = res.data[i][fields[j]];
                        }
                    }
                    fieldData.push(obj);
                }
                const ws = XLSX.utils.json_to_sheet(fieldData);
                ws['A1'] = { v: 'First Name' };
                ws['B1'] = { v: 'Middle Name' };
                ws['C1'] = { v: 'Last Name' };
                ws['D1'] = { v: 'Email' };
                ws['E1'] = { v: 'Phone Number' };
                ws['F1'] = { v: 'Alternative Number' };
                ws['G1'] = { v: 'Address' };
                ws['H1'] = { v: 'City' };
                ws['I1'] = { v: 'State' };
                ws['J1'] = { v: 'Zip Code' };
                ws['K1'] = { v: 'Date of Birth' };
                ws['L1'] = { v: 'SSN' };
                ws['M1'] = { v: 'Bank Name' };
                ws['N1'] = { v: 'Type of Account' };
                ws['O1'] = { v: 'Account Number' };
                ws['P1'] = { v: 'Routing Number' };
                ws['Q1'] = { v: 'Initial Amount' };
                ws['R1'] = { v: 'Monthly Instalment' };
                ws['S1'] = { v: 'Type of Loan' };
                ws['T1'] = { v: 'Have you borrowed any loan in past ?' };
                ws['U1'] = { v: 'Have you got a loan pending?' };
                ws['V1'] = { v: 'Do you have any outstanding credit cards payments or medical bills?' };
                ws['W1'] = { v: 'Apply Date' };
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                XLSX.writeFile(wb, 'Loan-details-' + Date.now() + '.xlsx', { compression: true });
            });
        } else {
            toast.error('Invalid date !!');
        }
    };
    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel={showCancel}
                    confirmBtnText={confirmBtnTitle}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={mainTitle}
                    show={show}
                    onCancel={() => setShow(false)}
                    onConfirm={(e) => confirmSelection(e)}
                ></SweetAlert>
            </>
        );
    };

    return (
        <>
            {/* Table */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <Container className="mt--6" fluid>
                <Row>
                    <SweetAlertFun />
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <h3 className="mb-0">{tableTitle}</h3>
                                    </Col>
                                    {createBtn && !showPaymentModal && (
                                        <Col md="6" className="text-right">
                                            <Link to={currentPath + '/create'}>
                                                <Button className="text-white btn-custom-green">Create Record</Button>
                                            </Link>
                                        </Col>
                                    )}
                                    {createBtn && showPaymentModal && (
                                        <Col md="6" className="text-right">
                                            <Button
                                                onClick={() => setShowModal(true)}
                                                className="text-white btn-custom-green"
                                            >
                                                Create Record
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>
                            <ToolkitProvider bootstrap4 keyField="id" data={data} columns={columns} search>
                                {(props) => (
                                    <div className="py-4">
                                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1 w-100">
                                            <Row>
                                                <Col md="9">
                                                    <Row>
                                                        <Col md="3" className="pr-0">
                                                            <select
                                                                onChange={(e) => handleSelectionType(e)}
                                                                name="userStatus"
                                                                className={'form-control form-control-sm'}
                                                            >
                                                                {selectDropDownArray.map(({ value, label, index }) => (
                                                                    <option key={index} value={value}>
                                                                        {label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        <Col md="3">
                                                            <Button
                                                                type="button"
                                                                className="text-white btn-sm btn-success mr-3"
                                                                onClick={submitSelection}
                                                            >
                                                                Submit
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                className="text-white btn-sm btn-info"
                                                                onClick={clearSelection}
                                                            >
                                                                Clear
                                                            </Button>
                                                        </Col>
                                                        {enableExport && (
                                                            <>
                                                                <Col md="2" className="pr-0">
                                                                    <input
                                                                        onChange={(e) => onDateChange(e)}
                                                                        placeholder="From date"
                                                                        name="fromDate"
                                                                        type="date"
                                                                        style={{
                                                                            height: 'calc(0.45em + 1.25rem + 5px)',
                                                                        }}
                                                                        className={
                                                                            'form-control-alternative form-control'
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md="2" className="pr-0">
                                                                    <input
                                                                        onChange={(e) => onDateChange(e)}
                                                                        placeholder="To date"
                                                                        name="toDate"
                                                                        type="date"
                                                                        style={{
                                                                            height: 'calc(0.45em + 1.25rem + 5px)',
                                                                        }}
                                                                        className={
                                                                            'form-control-alternative form-control'
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md="1">
                                                                    <Button
                                                                        onClick={getExport}
                                                                        type="button"
                                                                        className="text-white btn-sm btn-success mr-3"
                                                                    >
                                                                        Export
                                                                    </Button>
                                                                </Col>
                                                            </>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col md="3 text-right">
                                                    <label>
                                                        {/* Search: */}
                                                        <SearchBar
                                                            className="form-control-sm"
                                                            placeholder=""
                                                            {...props.searchProps}
                                                        />
                                                    </label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <BootstrapTable
                                            ref={tableRef}
                                            noDataIndication={indication}
                                            bootstrap4={true}
                                            defaultSorted={defaultSorted}
                                            bordered={false}
                                            pagination={pagination}
                                            {...props.baseProps}
                                            selectRow={selectRow}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal className="modal-dialog-centered" style={{ maxHeight: '600px' }} isOpen={showModal}>
                <div className="modal-header py-2">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('debit-card')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <div>
                                <img
                                    alt="..."
                                    // width={'60px'}
                                    height={'60px'}
                                    src={require('../assets/img/icons/cards/debitcard.png').default}
                                />{' '}
                                Debit Card
                            </div>
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('cash-app')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <div>
                                <img
                                    alt="..."
                                    height={'90px'}
                                    src={require('../assets/img/icons/cards/cashapp.png').default}
                                />
                            </div>
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('money-pak')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <img
                                alt="..."
                                width={'140px'}
                                height={'50px'}
                                src={require('../assets/img/icons/cards/moneypak.png').default}
                            />
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('zelle')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <img
                                alt="..."
                                // width={'120px'}
                                height={'70px'}
                                src={require('../assets/img/icons/cards/zelle.png').default}
                            />
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('google-pay')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <img
                                alt="..."
                                // width={'150px'}
                                height={'70px'}
                                src={require('../assets/img/icons/cards/googlepay.png').default}
                            />
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('apple-pay')}
                            className="mb-3 d-flex justify-content-center cursor-pointer"
                        >
                            <img
                                alt="..."
                                // width={'180px'}
                                height={'70px'}
                                src={require('../assets/img/icons/cards/applepay.png').default}
                            />
                        </Col>
                        <Col
                            lg="12"
                            onClick={() => goToWallet('paypal')}
                            className="d-flex justify-content-center cursor-pointer"
                        >
                            <img
                                alt="..."
                                // width={'100px'}
                                height={'50px'}
                                src={require('../assets/img/icons/cards/paypal.png').default}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default Tables;

import React, { useState, useEffect } from 'react';
import moment from 'moment';

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import { getContactByIdAPI } from '../../services/api';

const ContactUsView = (props) => {
	const [contactDetail, setContactDetails] = useState({});

	useEffect(() => {
		if (!props.match.path.endsWith('create')) {
			getContactByIdAPI(props.match.params.id).then((user) => {
				setContactDetails(user);
			});
		}
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='Contact Us Details' />
			{/* Page content */}

			<Container className='mt--7' fluid>
				<Row>
					<Col className='order-xl-1' xl='12'>
						<Card className='bg-secondary shadow'>
							<CardHeader className='bg-white border-0'>
								<Row className='align-items-center'>
									<Col xs='8'>
										<h3 className='mb-0'>View Inquiry Details</h3>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md='6' className='my-3'>
										<strong>Full Name :</strong>
									</Col>
									<Col md='6' className='my-3'>{contactDetail?.fullName}</Col>

									<Col md='6' className='my-3'>
										<strong>Email :</strong>
									</Col>
									<Col md='6' className='my-3'>{contactDetail?.email}</Col>

									<Col md='6' className='my-3'>
										<strong>Subject :</strong>
									</Col>
									<Col md='6' className='my-3'>{contactDetail?.subject}</Col>

									<Col md='6' className='my-3'>
										<strong>Message :</strong>
									</Col>
									<Col md='6' className='my-3'>{contactDetail?.message}</Col>

									<Col md='6' className='my-3'>
										<strong>Message Date :</strong>
									</Col>
									<Col md='6'>
										{contactDetail.createdAt
											? moment
													.utc(contactDetail.createdAt)
													.format('DD-MM-YYYY')
											: 'N/A'}
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ContactUsView;

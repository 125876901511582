import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getAllLoanAPI, deleteLoanAPI, updateMultipleLoanStatusAPI, updateLoanStatusAPI } from '../../services/api';
import { toast } from 'react-toastify';
import { Badge } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

const LoanList = () => {
    const [loanData, setLoanData] = useState([]);
    const data = loanData;
    const [softDeleteShow, setSoftDeleteShow] = useState(false);
    const [hardDeleteShow, setHardDeleteShow] = useState(false);
    const [statusAlert, setStatusAlert] = useState(false);
    const [id, setID] = useState(null);
    const [loanStatus, setLoanStatus] = useState(null);

    const columns = [
        { dataField: 'id', text: 'id', sort: true, hidden: true },
        {
            dataField: 'createdAt',
            text: 'Created On',
            sort: true,
            formatter: (cell, row) => <>{row.createdAt ? moment.utc(row.createdAt).format('DD-MM-YYYY') : 'N/A'}</>,
        },
        { dataField: 'firstName', text: 'First Name', sort: true },
        { dataField: 'middleName', text: 'Middle Name', sort: true },
        { dataField: 'lastName', text: 'Last Name', sort: true },
        { dataField: 'accountNo', text: 'Account No.', sort: true },
        {
            dataField: 'documentStatus',
            text: 'Doc Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.addressProof === null &&
                    row.backLicence === null &&
                    row.frontLicence === null &&
                    row.selfie === null ? (
                        // <Link
                        //     to={`/view-application/${row.id}`}
                        //     className="table-action table-action-toggle"
                        //     data-toggle="tooltip"
                        //     title="Pending Document"
                        //     data-original-title="Edit product"
                        // >
                        <Badge className="cursor-pointer bg-yellow text-white badge-default">Pending</Badge>
                    ) : // </Link>
                    row.addressProofStatus === '2' &&
                      row.addressProof &&
                      row.backLicenceStatus === '2' &&
                      row.backLicence &&
                      row.frontLicenceStatus === '2' &&
                      row.frontLicence &&
                      row.selfieStatus === '2' &&
                      row.selfie ? (
                        <Badge className="bg-info text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Submitted
                        </Badge>
                    ) : row.addressProofStatus === '1' &&
                      row.backLicenceStatus === '1' &&
                      row.frontLicenceStatus === '1' &&
                      row.selfieStatus === '1' ? (
                        <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Approved
                        </Badge>
                    ) : row.addressProofStatus === '0' ||
                      row.backLicenceStatus === '0' ||
                      row.frontLicenceStatus === '0' ||
                      row.selfieStatus === '0' ? (
                        <Badge className="bg-danger text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Rejected
                        </Badge>
                    ) : (
                        <Badge
                            className="cursor-pointer bg-info text-white badge-default"
                            // onClick={(e) => uploadDocument(e, row)}
                            onClick={(e) => e.preventDefault()}
                        >
                            Submitted
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'loanStatus',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.loanStatus === '2' ? (
                        <Badge className="bg-yellow text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Pending
                        </Badge>
                    ) : row.loanStatus === '1' ? (
                        <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Approve
                        </Badge>
                    ) : (
                        <Badge className="bg-danger text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Reject
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'actions',
            text: 'Action',
            isDummyField: true,
            formatter: (cell, row) => (
                <>
                    <Link
                        to={`/admin/link-bank-details/${row.userId}/${row.id}`}
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        data-original-title="Link bank details"
                    >
                        <i className="fas fa-money-check-alt"></i>
                    </Link>
                    <Link
                        to={`/admin/loan/edit/${row.id}`}
                        className="table-action table-action-edit"
                        data-toggle="tooltip"
                        data-original-title="Edit product"
                    >
                        <i className="fas fa-user-edit"></i>
                    </Link>
                    <a
                        href="#!"
                        className="table-action table-action-soft-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => deleteRow(e, row.id, 'soft-delete')}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                    <a
                        href="#!"
                        className="table-action table-action-hard-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => deleteRow(e, row.id, 'hard-delete')}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                    <a
                        href="#!"
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => updateLoanStatus(e, row.id, row.loanStatus)}
                    >
                        <i className={row.loanStatus === '1' ? 'fas fa-toggle-on' : 'fas fa-toggle-off'}></i>
                    </a>
                </>
            ),
        },
    ];

    const deleteRow = (e, id, type = '') => {
        setID(id);
        e.preventDefault();
        if (type === 'soft-delete') {
            SoftDeleteSweetAlert();
            setSoftDeleteShow(true);
        } else {
            setHardDeleteShow(true);
            HardDeleteSweetAlert();
        }
    };

    const updateLoanStatus = (e, id, loanStatus) => {
        setID(id);
        setLoanStatus(loanStatus);
        e.preventDefault();
        setStatusAlert(true);
        SweetAlertFunToggle();
    };

    const SoftDeleteSweetAlert = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, trash it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={softDeleteShow}
                    onCancel={() => setSoftDeleteShow(false)}
                    onConfirm={() => deleteLoan('soft-delete')}
                ></SweetAlert>
            </>
        );
    };

    const HardDeleteSweetAlert = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, permanently delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={hardDeleteShow}
                    onCancel={() => setHardDeleteShow(false)}
                    onConfirm={() => deleteLoan('hard-delete')}
                ></SweetAlert>
            </>
        );
    };

    const SweetAlertFunToggle = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, update status!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={statusAlert}
                    onCancel={() => setStatusAlert(false)}
                    onConfirm={() => updateStatus()}
                ></SweetAlert>
            </>
        );
    };

    const deleteLoan = (type) => {
        deleteLoanAPI(id, type).then((result) => {
            if (result) {
                type === 'hard-delete' ? setHardDeleteShow(false) : setSoftDeleteShow(false);
                toast.success('Record deleted successfully.');
                getAllLoan();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    const updateStatus = () => {
        const status = { loanStatus: loanStatus };
        updateLoanStatusAPI(id, status).then((result) => {
            if (result) {
                setStatusAlert(false);
                toast.success('Status updated successfully.');
                getAllLoan();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    useEffect(() => {
        getAllLoan();
    }, []);

    const getAllLoan = () => {
        getAllLoanAPI().then((user) => {
            setLoanData(user);
        });
    };

    const updateMultipleLoanStatus = (selectedRow, selectionType) => {
        updateMultipleLoanStatusAPI({
            IDArray: selectedRow,
            status: selectionType,
        }).then((result) => {
            if (result) {
                setSoftDeleteShow(false);
                toast.success('Status updated successfully.');
                getAllLoan();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    return (
        <>
            <Breadcrumbs breadcrumbName="Loan Management" />
            <SoftDeleteSweetAlert />
            <HardDeleteSweetAlert />
            <SweetAlertFunToggle />
            <Tables
                data={data}
                columns={columns}
                defaultSortKey="createdAt"
                defaultSortDirection="desc"
                currentPath="loan"
                tableTitle="Loan List"
                enableExport={true}
                createBtn="true"
                updateMultipleStatus={(selectedRow, selectionType) =>
                    updateMultipleLoanStatus(selectedRow, selectionType)
                }
            />
        </>
    );
};

export default LoanList;

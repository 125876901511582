import constant from './constants.js';
import { getToken, logout } from './dataService.js';

export var loginAPI = (data) => {
    return fetch(constant.API_URL + '/api/login', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-Type': 'application/json' },
    })
        .then((res) => res.json())
        .then(
            (result) => result,
            (err) => err
        );
};

export var createUserAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users', {
        method: 'POST',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createLoanAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan', {
        method: 'POST',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateLoanAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id, {
        method: 'PUT',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getDashboardDataApi = (data) => {
    return fetch(constant.API_URL + '/api/loan/dashboard-data', {
        method: 'POST',
        headers: {
            'content-Type': 'application/json',
            body: JSON.stringify(data),
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllLoanAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getLoanByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateLoanStatusAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/status/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateMultipleLoanStatusAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteLoanAPI = (id, type) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/' + id + '/' + type, {
        method: 'DELETE',
        // body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateDocumentStatusByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/document/status/' + id, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllUserAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateUserAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: 'PUT',
        body: data,
        headers: {
            // 'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateUserStatusAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/status/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateMultipleUserStatusAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteUserAPI = (id, type) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id + '/' + type, {
        method: 'DELETE',
        // body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getUserByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllContactAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getContactByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteContactAPI = (id, type) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/' + id + '/' + type, {
        method: 'DELETE',
        // body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteMultipleContactAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/contact-us/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateAdminAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/admin/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAdminByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/admin/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createOrUpdateBankDetailsAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/link-bank-details', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getBankDetailsByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/link-bank-details/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getLoanExcelData = (from, end) => {
    // console.log(from, end);
    showSpinner();
    return fetch(constant.API_URL + '/api/loan/excel?start=' + from + '&end=' + end, {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
            start: from,
            end: end,
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var createWalletAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllWalletAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet', {
        method: 'GET',
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateWalletAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteWalletAPI = (id, type) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet/' + id + '/' + type, {
        method: 'DELETE',
        // body: JSON.stringify(type),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateMultipleWalletStatusAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/wallet/multiple/status/', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getWalletByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/wallet/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAboutUsByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + '/api/about-us/' + id, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateAboutUsAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + '/api/about-us/' + id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'content-Type': 'application/json',
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

const showSpinner = () => {
    document.getElementById('main-content').style.display = 'none';
    if (document.querySelector('.spinner')) document.querySelector('.spinner').style.display = 'block';
};

const hideSpinner = () => {
    document.getElementById('main-content').style.display = 'block';
    if (document.querySelector('.spinner')) document.querySelector('.spinner').style.display = 'none';
};

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/style.scss';

import AdminLayout from './layouts/Admin.js';
import AuthLayout from './layouts/Auth.js';
import ProtectedRoutes from './ProtectedRoutes.js';
ReactDOM.render(
	<BrowserRouter basename='#'>
		<Switch>
			<Route path='/auth' component={AuthLayout} />
			<ProtectedRoutes path='/' component={AdminLayout} />
			<ProtectedRoutes path='/admin' component={AdminLayout} />
			<ProtectedRoutes path='/admin/dashboard' component={AdminLayout} />
		</Switch>
	</BrowserRouter>,
	document.getElementById('root')
);

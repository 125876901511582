import React from 'react';
import { Redirect, Route } from 'react-router';

const ProtectedRoutes = ({ component: Component, ...rest }) => {
	const token = localStorage.getItem('token');
	return (
		<Route
			{...rest}
			render={(props) => {
				if (token) {
					return <Component {...rest} {...props} />;
				} else {
					return <Redirect to='/auth/login' />;
				}
			}}></Route>
	);
};

export default ProtectedRoutes;
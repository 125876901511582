import React, { useState, useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Spinner from 'react-bootstrap/Spinner';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import routes from '../routes.js';

const Admin = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();

	const [state, setState] = useState({
		sidenavOpen: true,
	});

	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		document.body.classList.remove('bg-custom');
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	// toggles collapse between mini sidenav and normal
	const toggleSidenav = (e) => {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-show');
			document.body.classList.remove('nav-open');
			document.body.classList.add('g-sidenav-hidden');
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-show');
			document.body.classList.add('nav-open');
			document.body.classList.remove('g-sidenav-hidden');
			document.body.classList.remove('g-sidenav-hide');
		}
		setState({
			sidenavOpen: !state.sidenavOpen,
		});
	};

	const hideOnclickOutside = () => {
		if (
			document.body.classList.contains('g-sidenav-pinned') &&
			width < 1200
		) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-show');
			document.body.classList.remove('nav-open');
			document.body.classList.add('g-sidenav-hidden');
		}
	};

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return 'Brand';
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
			<div style={{ display: 'none' }} className='spinner'></div>
			<div id='main-content'>
				<Sidebar
					{...props}
					routes={routes.filter((item) => item.icon !== undefined)}
					logo={{
						innerLink: '/admin/dashboard',
						imgSrc: require('../assets/img/brand/logo.png').default,
						imgAlt: '...',
					}}
					toggleSidenav={toggleSidenav}
					sidenavOpen={state.sidenavOpen}
				/>
				<div
					className='main-content'
					onClick={hideOnclickOutside}
					ref={mainContent}>
					<Header
						{...props}
						brandText={getBrandText(props.location.pathname)}
					/>
					<Switch>
						{getRoutes(routes)}
						<Redirect from='*' to='/admin/dashboard' />
					</Switch>
					<Container fluid>
						<Footer />
					</Container>
				</div>
			</div>
		</>
	);
};

export default Admin;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Container,
	Row,
	Col,
	Button,
} from 'reactstrap';

import Breadcrumbs from '../components/Breadcrumbs';
import { toast } from 'react-toastify';
import { updateAboutUsAPI, getAboutUsByIdAPI } from '../services/api';
import { useHistory } from 'react-router-dom';

const AboutUs = (props) => {
	const titleName = 'About Us';
	const buttonName = 'Update';
	const [aboutUsDetails, setAboutUsDetails] = useState({});
	const history = useHistory();
	const aboutUsId = 'a6aeee73-deaa-11ec-a625-f430b9920047';
	const validationSchema = Yup.object().shape({
		mobile1Text: Yup.string().required('Mobile 1 Text is required'),
		mobile1: Yup.string().required('Mobile 1 Text is required'),
		mobile2: Yup.string().required('Mobile 2 Text is required'),
		mobile2Text: Yup.string().required('Mobile 2 Text is required'),
		mobile3: Yup.string().required('Mobile 3 Text is required'),
		mobile3Text: Yup.string().required('Mobile 3 Text is required'),
		email1: Yup.string().required('Email 1 is required'),
		email2: Yup.string().required('Email 2 is required'),
		address: Yup.string().required('Address is required'),
	});

	var updateAboutUs = (data) => {
		updateAboutUsAPI(aboutUsId, data).then((result) => {
			if (result) {
				toast.success('Record updated successfully.');
				history.push('/admin/about-us');
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	useEffect(() => {
		getAboutUsByIdAPI(aboutUsId).then((user) => {
			setAboutUsDetails(user);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs breadcrumbName='About Us CMS' />
			{/* Page content */}
			<Formik
				onSubmit={(values, actions) => {
					updateAboutUs(values);
				}}
				initialValues={aboutUsDetails}
				enableReinitialize
				validationSchema={validationSchema}
				render={({ handleSubmit, errors, touched }) => (
					<Container className='mt--7' fluid>
						<Row>
							<Col className='order-xl-1' xl='12'>
								<Card className='bg-secondary shadow'>
									<CardHeader className='bg-white border-0'>
										<Row className='align-items-center'>
											<Col xs='8'>
												<h3 className='mb-0'>{titleName}</h3>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<form onSubmit={handleSubmit}>
											<div className='pl-lg-4'>
												<Row>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 1 Text
															</label>
															<Field
																name='mobile1Text'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 1 Text'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile1Text && touched.mobile1Text
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile1Text'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 1
															</label>
															<Field
																name='mobile1'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 1'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile1 && touched.mobile1
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile1'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>

													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 2 Text
															</label>
															<Field
																name='mobile2Text'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 2 Text'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile2Text && touched.mobile2Text
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile2Text'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 2
															</label>
															<Field
																name='mobile2'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 2'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile2 && touched.mobile2
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile2'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>

													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 3 Text
															</label>
															<Field
																name='mobile3Text'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 3 Text'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile3Text && touched.mobile3Text
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile3Text'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Mobile 3
															</label>
															<Field
																name='mobile3'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Mobile 3'
																className={
																	'form-control-alternative form-control' +
																	(errors.mobile3 && touched.mobile3
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='mobile3'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>

													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Email 1
															</label>
															<Field
																name='email1'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Email 1'
																className={
																	'form-control-alternative form-control' +
																	(errors.email1 && touched.email1
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='email1'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
													<Col lg='6'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Email 2
															</label>
															<Field
																name='email2'
																type='text'
																id='input-withdrawal-Amount'
																placeholder='Email 2'
																className={
																	'form-control-alternative form-control' +
																	(errors.email2 && touched.email2
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='email2'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>

													<Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='input-withdrawal-Amount'>
																Address
															</label>
															<Field
																name='address'
																type='text'
																as="textarea"
																rows="5"
																id='input-withdrawal-Amount'
																placeholder='Address'
																className={
																	'form-control-alternative form-control' +
																	(errors.address && touched.address
																		? ' error-box is-invalid'
																		: '')
																}
															/>
															<ErrorMessage
																name='address'
																component='div'
																className='is-invalid invalid-feedback'
															/>
														</FormGroup>
													</Col>
												</Row>
											</div>
											<hr className='my-4' />
											<div className='pl-lg-4'>
												<Row>
													<Col md='12' className='text-right'>
														<Link to={'/admin'}>
															<Button className='mr-3 text-black'>
																Cancel
															</Button>
														</Link>
														<Button
															type='submit'
															className='text-white btn-info'>
															{buttonName}
														</Button>
													</Col>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				)}
			/>
		</>
	);
};

export default AboutUs;

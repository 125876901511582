import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Badge } from 'reactstrap';
import {
	getAllUserAPI,
	deleteUserAPI,
	updateUserStatusAPI,
	updateMultipleUserStatusAPI,
} from '../../services/api';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';

const UserList = () => {
	const history = useHistory();
	const [userData, setUserData] = useState([]);
	const data = userData;
	const [softDeleteShow, setSoftDeleteShow] = useState(false);
	const [hardDeleteShow, setHardDeleteShow] = useState(false);
	const [statusAlert, setStatusAlert] = useState(false);
	const [id, setID] = useState(null);
	const [userStatus, setUserStatus] = useState(null);

	const columns = [
		{ dataField: 'id', text: 'id', sort: true, hidden: true },
		{ dataField: 'firstName', text: 'First Name', sort: true },
		{ dataField: 'middleName', text: 'Middle Name', sort: true },
		{ dataField: 'lastName', text: 'Last Name', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created On',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.createdAt
						? moment.utc(row.createdAt).format('DD-MM-YYYY')
						: 'N/A'}
				</>
			),
		},
		{
			dataField: 'userStatus',
			text: 'Status',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.userStatus === '1' ? (
						<Badge
							className='bg-success text-white badge-default'
							onClick={(e) => e.preventDefault()}>
							Active
						</Badge>
					) : (
						<Badge
							className='bg-danger text-white badge-default'
							onClick={(e) => e.preventDefault()}>
							Inactive
						</Badge>
					)}
				</>
			),
		},
		{
			dataField: 'actions',
			text: 'Action',
			isDummyField: true,
			formatter: (cell, row) => (
				<>
					<Link
						to={`/admin/user/edit/${row.id}`}
						className='table-action table-action-edit'
						data-toggle='tooltip'
						data-original-title='Edit product'>
						<i className='fas fa-user-edit'></i>
					</Link>
					<a
						href='#!'
						className='table-action table-action-soft-delete'
						data-toggle='tooltip'
						data-original-title='Delete product'
						onClick={(e) => deleteRow(e, row.id, 'soft-delete')}>
						<i className='fas fa-trash'></i>
					</a>
					<a
						href='#!'
						className='table-action table-action-hard-delete'
						data-toggle='tooltip'
						data-original-title='Delete product'
						onClick={(e) => deleteRow(e, row.id, 'hard-delete')}>
						<i className='fas fa-trash'></i>
					</a>
					<a
						href='#!'
						className='table-action table-action-toggle'
						data-toggle='tooltip'
						data-original-title='Delete product'
						onClick={(e) => updateUserStatus(e, row.id, row.userStatus)}>
						<i
							className={
								row.userStatus === '1'
									? 'fas fa-toggle-on'
									: 'fas fa-toggle-off'
							}></i>
					</a>
				</>
			),
		},
	];

	const deleteRow = (e, id, type = '') => {
		setID(id);
		e.preventDefault();
		if (type === 'soft-delete') {
			SoftDeleteSweetAlert();
			setSoftDeleteShow(true);
		} else {
			setHardDeleteShow(true);
			HardDeleteSweetAlert();
		}
	};

	const updateUserStatus = (e, id, userStatus) => {
		setID(id);
		setUserStatus(userStatus);
		e.preventDefault();
		setStatusAlert(true);
		SweetAlertFunToggle();
	};

	const SoftDeleteSweetAlert = () => {
		return (
			<>
				<SweetAlert
					warning
					showCancel
					confirmBtnText='Yes, trash it!'
					confirmBtnBsStyle='danger'
					cancelBtnBsStyle='default'
					title='Are you sure?'
					show={softDeleteShow}
					onCancel={() => setSoftDeleteShow(false)}
					onConfirm={() => deleteUser('soft-delete')}></SweetAlert>
			</>
		);
	};

	const HardDeleteSweetAlert = () => {
		return (
			<>
				<SweetAlert
					warning
					showCancel
					confirmBtnText='Yes, permanently delete it!'
					confirmBtnBsStyle='danger'
					cancelBtnBsStyle='default'
					title='Are you sure?'
					show={hardDeleteShow}
					onCancel={() => setHardDeleteShow(false)}
					onConfirm={() => deleteUser('hard-delete')}></SweetAlert>
			</>
		);
	};

	const SweetAlertFunToggle = () => {
		return (
			<>
				<SweetAlert
					warning
					showCancel
					confirmBtnText='Yes, update status!'
					confirmBtnBsStyle='danger'
					cancelBtnBsStyle='default'
					title='Are you sure?'
					show={statusAlert}
					onCancel={() => setStatusAlert(false)}
					onConfirm={() => updateStatus()}></SweetAlert>
			</>
		);
	};

	const deleteUser = (type) => {
		deleteUserAPI(id, type).then((result) => {
			if (result) {
				type === 'hard-delete'
					? setHardDeleteShow(false)
					: setSoftDeleteShow(false);
				toast.success('Record deleted successfully.');
				setTimeout(() => {
					history.push('/admin/user');
					getAllUserAPI().then((user) => {
						setUserData(user);
					});
				}, 2000);
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	const updateStatus = () => {
		const status = { userStatus };
		updateUserStatusAPI(id, status).then((result) => {
			if (result) {
				setStatusAlert(false);
				toast.success('Status updated successfully.');
				getAllUser();
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	useEffect(() => {
		getAllUser();
	}, []);

	const getAllUser = () => {
		getAllUserAPI().then((user) => {
			setUserData(user);
		});
	};

	const updateMultipleUserStatus = (selectedRow, selectionType) => {
		updateMultipleUserStatusAPI({
			IDArray: selectedRow,
			status: selectionType,
		}).then((result) => {
			if (result) {
				setSoftDeleteShow(false);
				toast.success('Status updated successfully.');
				getAllUser();
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	return (
		<>
			<Breadcrumbs breadcrumbName='User Management' />
			<SoftDeleteSweetAlert />
			<HardDeleteSweetAlert />
			<SweetAlertFunToggle />
			<Tables
				data={data}
				columns={columns}
				defaultSortKey='createdAt'
				defaultSortDirection='desc'
				currentPath='user'
				tableTitle='User List'
				createBtn='true'
				updateMultipleStatus={(selectedRow, selectionType) =>
					updateMultipleUserStatus(selectedRow, selectionType)
				}
			/>
		</>
	);
};

export default UserList;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Badge } from 'reactstrap';
import { getAllWalletAPI, deleteWalletAPI, updateMultipleWalletStatusAPI } from '../../services/api';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';

const WalletList = () => {
    const history = useHistory();
    const [walletData, setWalletData] = useState([]);
    const data = walletData;
    const [softDeleteShow, setSoftDeleteShow] = useState(false);
    const [hardDeleteShow, setHardDeleteShow] = useState(false);
    const [id, setID] = useState(null);

    const columns = [
        { dataField: 'id', text: 'id', sort: true, hidden: true },
        { dataField: 'walletId', text: 'walletid', sort: true, hidden: true },
        { dataField: 'withdrawalAmount', text: 'Withdraw Amount', sort: true },
        { dataField: 'paymentMethod', text: 'Payment/Transaction Method', sort: true },
        {
            dataField: 'remarks',
            text: 'Remarks',
            sort: true,
            formatter: (cell, row) => <>{row?.remarks ? row.remarks : 'N/A'}</>,
        },
        {
            dataField: 'walletStatus',
            text: 'Wallet Status',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.walletStatus === '2' ? (
                        <Badge className="bg-yellow text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Pending
                        </Badge>
                    ) : row.walletStatus === '1' ? (
                        <Badge className="bg-success text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Approved
                        </Badge>
                    ) : (
                        <Badge className="bg-danger text-white badge-default" onClick={(e) => e.preventDefault()}>
                            Unapproved
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: 'actions',
            text: 'Action',
            isDummyField: true,
            formatter: (cell, row) => (
                <>
                    <Link
                        to={`/admin/wallet/edit/${row?.paymentMethod}/${row.id}`}
                        className="table-action table-action-edit"
                        data-toggle="tooltip"
                        data-original-title="Edit product"
                    >
                        <i className="fas fa-user-edit"></i>
                    </Link>
                    <a
                        href="#!"
                        className="table-action table-action-soft-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => deleteRow(e, row.id, 'soft-delete')}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                    <a
                        href="#!"
                        className="table-action table-action-hard-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => deleteRow(e, row.id, 'hard-delete')}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                </>
            ),
        },
    ];

    const deleteRow = (e, id, type = '') => {
        setID(id);
        e.preventDefault();
        if (type === 'soft-delete') {
            SoftDeleteSweetAlert();
            setSoftDeleteShow(true);
        } else {
            setHardDeleteShow(true);
            HardDeleteSweetAlert();
        }
    };

    const SoftDeleteSweetAlert = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, trash it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={softDeleteShow}
                    onCancel={() => setSoftDeleteShow(false)}
                    onConfirm={() => deleteWallet('soft-delete')}
                ></SweetAlert>
            </>
        );
    };

    const HardDeleteSweetAlert = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, permanently delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={hardDeleteShow}
                    onCancel={() => setHardDeleteShow(false)}
                    onConfirm={() => deleteWallet('hard-delete')}
                ></SweetAlert>
            </>
        );
    };

    const deleteWallet = (type) => {
        deleteWalletAPI(id, type).then((result) => {
            if (result) {
                type === 'hard-delete' ? setHardDeleteShow(false) : setSoftDeleteShow(false);
                toast.success('Record deleted sucessfully.');
                setTimeout(() => {
                    history.push('/admin/wallet');
                    getAllWalletAPI().then((wallet) => {
                        setWalletData(wallet);
                    });
                }, 2000);
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    useEffect(() => {
        getAllWallet();
    }, []);

    const getAllWallet = () => {
        getAllWalletAPI().then((wallet) => {
            setWalletData(wallet);
        });
    };

    const updateMultipleWalletStatus = (selectedRow, selectionType) => {
        updateMultipleWalletStatusAPI({
            IDArray: selectedRow,
            status: selectionType,
        }).then((result) => {
            if (result) {
                setSoftDeleteShow(false);
                toast.success('Status updated successfully.');
                getAllWallet();
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    return (
        <>
            <Breadcrumbs breadcrumbName="Wallet Transaction Management" />
            <SoftDeleteSweetAlert />
            <HardDeleteSweetAlert />
            <Tables
                data={data}
                columns={columns}
                defaultSortKey="createdAt"
                defaultSortDirection="desc"
                currentPath="wallet"
                tableTitle="Wallet Transaction List"
                createBtn="true"
                showPaymentModal={true}
                updateMultipleStatus={(selectedRow, selectionType) =>
                    updateMultipleWalletStatus(selectedRow, selectionType)
                }
            />
        </>
    );
};

export default WalletList;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import service from 'services/constants';

// reactstrap components
import { Card, CardHeader, CardBody, FormGroup, Container, Row, Col, Button } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    createLoanAPI,
    getAllUserAPI,
    getLoanByIdAPI,
    updateLoanAPI,
    updateDocumentStatusByIdAPI,
} from '../../services/api';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

const LoanView = (props) => {
    const [titleName, setTitle] = useState(props.match.path.endsWith('create') ? 'Create Loan' : 'Update Loan');
    const [buttonName, setButton] = useState(props.match.path.endsWith('create') ? 'Submit' : 'Update');
    const [userDetail, setUserDetails] = useState([]);
    const [loanDetail, setLoanDetails] = useState([]);

    const [selectedUserDetail, setSelectedUserDetails] = useState({});
    const [frontImg, setFrontImg] = useState(null);
    const [backImg, setBackImg] = useState(null);
    const [addressImg, setAddressImg] = useState(null);
    const [selfieImg, setSelfieImg] = useState(null);

    const [frontBase64, setFrontBase64] = useState(null);
    const [backBase64, setBackBase64] = useState(null);
    const [addressBase64, setAddressBase64] = useState(null);
    const [selfieBase64, setSelfieBase64] = useState(null);

    const [pastLoanValue, setPastLoanValue] = useState('No');
    const [pendingLoanValue, setPendingLoanValue] = useState('No');
    const [pendingBillsValue, setPendingBillsValue] = useState('No');
    const [setaccountType, setAccountType] = useState(null);
    const [setloanType, setLoanType] = useState(null);
    const [setmonths, setMonths] = useState(null);
    const [setresidingYear, setResidingYear] = useState(null);
    const [show, setShow] = useState(false);
    const [statusObj, setStatusObj] = useState(false);
    const history = useHistory();

    const [frontStatus, setFrontStatus] = useState(null);
    const [backStatus, setBackStatus] = useState(null);
    const [addressStatus, setAddressStatus] = useState(null);
    const [selfieStatus, setSelfieStatus] = useState(null);
    const [pastLoanHere, setIsLoanPast] = useState(null);
    const [pendingLoanHere, setIsLoanPending] = useState(null);
    const [pendingBillHere, setIsBillPending] = useState(null);

    const formFields = {
        firstName: '' || selectedUserDetail.firstName,
        middleName: '' || selectedUserDetail.middleName,
        lastName: '' || selectedUserDetail.lastName,
        phoneNo1: '' || selectedUserDetail.phoneNo1,
        address1: '' || selectedUserDetail.address1,
        email: '' || selectedUserDetail.email,
        phoneNo2: '',
        dob: '',
        ssn: '',
        address2: '',
        city: '',
        state: '',
        pinCode: '',
        bankName: '',
        accountNo: '',
        routingNo: '',
        bankAddress: '',
        initialAmount: '',
        repaymentMonth: '',
        // rejectReason : '',
        loanStatus: '',
        residingYear: '',
        loanType: '',
        months: '',
        accountType: '',
        // licenceFront: "",
        // licenceBack: "",
        // addressProof: "",
        // selfie: "",
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        middleName: Yup.string().required('Middle Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phoneNo1: Yup.string().min(10).required('Phone number is required'),
        address1: Yup.string().required('Address is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        phoneNo2: Yup.string().min(10).required('Home Phone number is required'),
        dob: Yup.string().required('Date of Birth is required'),
        ssn: Yup.string().required('SSN number is required'),
        address2: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        pinCode: Yup.string().required('Zip code is required'),
        bankName: Yup.string().required('Bank Name is required'),
        accountNo: Yup.string().required('Bank Account Number is required'),
        routingNo: Yup.string().required('Routing Number is required'),
        bankAddress: Yup.string().required('Bank Address is required'),
        initialAmount: Yup.string().required('Loan Initial Amount is required'),
        repaymentMonth: Yup.string().required('Loan Month is required'),
        // rejectReason : Yup.string().required("Address is required"),
        loanStatus: Yup.string().required('Please select Loan status'),
        residingYear: Yup.string().required('Please select Residency Year'),
        loanType: Yup.string().required('Please select Loan Type'),
        months: Yup.string().required('Please select Loan Months'),
        accountType: Yup.string().required('Please select Bank Account Type'),
        // licenceFront: Yup.string().required("Please upload front document"),
        // licenceBack: Yup.string().required("Please upload back document"),
        // addressProof: Yup.string().required("Please upload address document"),
        // selfie: Yup.string().required("Please upload your selfie"),
        // userStatus: Yup.string().required("Please select status"),
    });

    const onHandleChange = (e) => {
        // console.log(e.target.name)
        if (e.target.name === 'isPastLoan') {
            setPastLoanValue(e.target.value);
        }
        if (e.target.name === 'isPendingLoan') {
            setPendingLoanValue(e.target.value);
        }
        if (e.target.name === 'isPendingBills') {
            setPendingBillsValue(e.target.value);
        }
        if (e.target.name === 'accountType') {
            setAccountType(e.target.value);
        }
        if (e.target.name === 'loanType') {
            setLoanType(e.target.value);
        }
        if (e.target.name === 'months') {
            setMonths(e.target.value);
        }
        if (e.target.name === 'residingYear') {
            setResidingYear(e.target.value);
        }
        if (e.target.name === 'email') {
            const userID = e.target.value;
            const user = userDetail.filter((item) => item.id === userID);
            if (user && user.length > 0) {
                setSelectedUserDetails({
                    userId: user[0].id,
                    firstName: user[0].firstName,
                    lastName: user[0].lastName,
                    middleName: user[0].middleName,
                    phoneNo1: user[0].phoneNo1,
                    address1: user[0].address1,
                    email: user[0].email,
                });
            }
        }
    };

    const getFrontImage = (e) => {
        const file = e.target.files[0];
        const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
        const type = file && e.target.files[0].type;

        if (file.size > 2097152) {
            toast.error('File size exceeds maximum limit 2 MB.');
            e.target.value = '';
            setFrontBase64(null);
            return;
        }
        if (file && !allowedType.includes(type)) {
            toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
            e.target.value = '';
            setFrontBase64(null);
            return;
        }
        displayImage(file, 'front');
        setFrontImg(file);
    };

    const displayImage = (file, string) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (string === 'front') {
                setFrontBase64(reader.result);
            } else if (string === 'back') {
                setBackBase64(reader.result);
            } else if (string === 'address') {
                setAddressBase64(reader.result);
            } else {
                setSelfieBase64(reader.result);
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const getBackImage = (e) => {
        const file = e.target.files[0];
        const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
        const type = file && e.target.files[0].type;

        if (file.size > 2097152) {
            toast.error('File size exceeds maximum limit 2 MB.');
            e.target.value = '';
            setBackBase64(null);
            return;
        }
        if (file && !allowedType.includes(type)) {
            toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
            e.target.value = '';
            setBackBase64(null);
            return;
        }
        displayImage(file, 'back');
        setBackImg(file);
    };

    const getAddressImage = (e) => {
        const file = e.target.files[0];
        const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
        const type = file && e.target.files[0].type;

        if (file.size > 2097152) {
            toast.error('File size exceeds maximum limit 2 MB.');
            e.target.value = '';
            setAddressBase64(null);
            return;
        }
        if (file && !allowedType.includes(type)) {
            toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
            e.target.value = '';
            setAddressBase64(null);
            return;
        }
        displayImage(file, 'address');
        setAddressImg(file);
    };

    const getSelfieImage = (e) => {
        const file = e.target.files[0];
        const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
        const type = file && e.target.files[0].type;

        if (file.size > 2097152) {
            toast.error('File size exceeds maximum limit 2 MB.');
            e.target.value = '';
            setSelfieBase64(null);
            return;
        }
        if (file && !allowedType.includes(type)) {
            toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
            e.target.value = '';
            setSelfieBase64(null);
            return;
        }
        displayImage(file, 'selfie');
        setSelfieImg(file);
    };

    var addLoan = (data) => {
        var loanAdd = {
            ...data,
            userId: selectedUserDetail.userId,
            isPastLoan: pastLoanValue === 'Yes' ? '1' : '0',
            isPendingLoan: pendingLoanValue === 'Yes' ? '1' : '0',
            isPendingBills: pendingBillsValue === 'Yes' ? '1' : '0',
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(loanAdd));
        formData.append('licenceFront', frontImg);
        formData.append('licenceBack', backImg);
        formData.append('addressProof', addressImg);
        formData.append('selfie', selfieImg);

        createLoanAPI(formData).then((result) => {
            if (result.result) {
                toast.success('Record added successfully.');
                history.push('/admin/loan');
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    var updateLoan = (data) => {
        var loanData = {
            ...data,
            isPastLoan: pastLoanValue === 'Yes' ? '1' : '0',
            isPendingLoan: pendingLoanValue === 'Yes' ? '1' : '0',
            isPendingBills: pendingBillsValue === 'Yes' ? '1' : '0',
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(loanData));
        formData.append('licenceFront', frontImg);
        formData.append('licenceBack', backImg);
        formData.append('addressProof', addressImg);
        formData.append('selfie', selfieImg);

        updateLoanAPI(props.match.params.id, formData).then((result) => {
            if (result) {
                toast.success('Record updated successfully.');
                history.push('/admin/loan');
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    const downloadImage = (e, image) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.href = `${image}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        getAllUserAPI().then((user) => {
            setUserDetails(user);
        });
        if (!props.match.path.endsWith('create')) {
            getLoanByIdAPI(props.match.params.id).then((loan) => {
                setIsLoanPast(loan && loan[0].isPastLoan == '0' ? false : true);
                setIsLoanPending(loan && loan[0].isPendingLoan == '0' ? false : true);
                setIsBillPending(loan && loan[0].isPendingBills == '0' ? false : true);
                setLoanDetails(loan && loan[0]);
                setFrontBase64(
                    loan && loan[0]['frontLicence'] !== null && service.API_URL + '/' + loan[0]['frontLicence']
                );
                setBackBase64(
                    loan && loan[0]['backLicence'] !== null && service.API_URL + '/' + loan[0]['backLicence']
                );
                setAddressBase64(
                    loan && loan[0]['addressProof'] !== null && service.API_URL + '/' + loan[0]['addressProof']
                );
                setSelfieBase64(loan && loan[0]['selfie'] !== null && service.API_URL + '/' + loan[0]['selfie']);
                setFrontStatus(loan && loan[0] && loan[0]['frontLicenceStatus']);
                setBackStatus(loan && loan[0] && loan[0]['backLicenceStatus']);
                setAddressStatus(loan && loan[0] && loan[0]['addressProofStatus']);
                setSelfieStatus(loan && loan[0] && loan[0]['selfieStatus']);
            });
        }
    }, []);

    const handleDocStatus = (event, fieldName, status) => {
        event.preventDefault();
        setShow(true);
        const obj = {
            fieldName,
            status,
        };
        setStatusObj(obj);
        SweetAlertFun();
    };

    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, update status!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={show}
                    onCancel={() => setShow(false)}
                    onConfirm={() => updateDocStatus()}
                ></SweetAlert>
            </>
        );
    };

    const updateDocStatus = () => {
        updateDocumentStatusByIdAPI(props.match.params.id, statusObj).then((result) => {
            if (result && result.success) {
                toast.success(result && result.message);
                if (!props.match.path.endsWith('create')) {
                    getLoanByIdAPI(props.match.params.id).then((loan) => {
                        setLoanDetails(loan && loan[0]);
                        setFrontBase64(loan && loan[0] && service.API_URL + '/' + loan[0]['frontLicence']);
                        setBackBase64(loan && loan[0] && service.API_URL + '/' + loan[0]['backLicence']);
                        setAddressBase64(loan && loan[0] && service.API_URL + '/' + loan[0]['addressProof']);
                        setSelfieBase64(loan && loan[0] && service.API_URL + '/' + loan[0]['selfie']);
                        setFrontStatus(loan && loan[0] && loan[0]['frontLicenceStatus']);
                        setBackStatus(loan && loan[0] && loan[0]['backLicenceStatus']);
                        setAddressStatus(loan && loan[0] && loan[0]['addressProofStatus']);
                        setSelfieStatus(loan && loan[0] && loan[0]['selfieStatus']);
                    });
                }
                setShow(false);
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <SweetAlertFun />
            <Breadcrumbs breadcrumbName="Loan Management" />
            {/* Page content */}
            <Formik
                onSubmit={(values, actions) => {
                    if (props.match.path.endsWith('create')) {
                        addLoan(values);
                    } else {
                        updateLoan(values);
                    }
                }}
                initialValues={
                    !props.match.path.endsWith('create')
                        ? loanDetail
                        : // : formFields.email !== undefined
                          // ? selectedUserDetail
                          formFields
                }
                enableReinitialize
                validationSchema={validationSchema}
                render={({ handleSubmit, errors, touched }) => (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">{titleName}</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={handleSubmit}>
                                            <h6 className="heading-small text-muted mb-4">Personal Details</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        {!props.match.path.endsWith('create') && (
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Email
                                                                </label>
                                                                <Field
                                                                    name="email"
                                                                    readOnly={true}
                                                                    className={
                                                                        'form-control-alternative form-control' +
                                                                        (errors.email && touched.email
                                                                            ? ' error-box is-invalid'
                                                                            : '')
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        )}
                                                        {props.match.path.endsWith('create') && (
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Email
                                                                </label>
                                                                <Field
                                                                    as="select"
                                                                    name="email"
                                                                    className={
                                                                        'form-control-alternative form-control' +
                                                                        (errors.email && touched.email
                                                                            ? ' error-box is-invalid'
                                                                            : '')
                                                                    }
                                                                    // onChange={onHandleChange}
                                                                >
                                                                    {(props) => (
                                                                        <React.Fragment>
                                                                            <select
                                                                                name="email"
                                                                                className={
                                                                                    'form-control-alternative form-control' +
                                                                                    (errors.email && touched.email
                                                                                        ? ' error-box is-invalid'
                                                                                        : '')
                                                                                }
                                                                                onChange={onHandleChange}
                                                                            >
                                                                                <option selected value="" disabled>
                                                                                    Select Email Address
                                                                                </option>
                                                                                {userDetail.map((e, key) => {
                                                                                    return (
                                                                                        <option key={key} value={e.id}>
                                                                                            {e.email}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </React.Fragment>
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="is-invalid invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                First name
                                                            </label>
                                                            <Field
                                                                name="firstName"
                                                                type="text"
                                                                id="input-first-name"
                                                                placeholder="First name"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.firstName && touched.firstName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="firstName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-middle-name"
                                                            >
                                                                Middle Name
                                                            </label>
                                                            <Field
                                                                id="input-middle-name"
                                                                placeholder="Middle Name"
                                                                name="middleName"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.middleName && touched.middleName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="middleName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Last name
                                                            </label>
                                                            <Field
                                                                id="input-last-name"
                                                                placeholder="Last name"
                                                                name="lastName"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.lastName && touched.lastName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="lastName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-phone-number"
                                                            >
                                                                Phone Number
                                                            </label>
                                                            <Field
                                                                id="input-phone-number"
                                                                placeholder="9874563210"
                                                                name="phoneNo1"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.phoneNo1 && touched.phoneNo1
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="phoneNo1"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-phone-number"
                                                            >
                                                                Home Phone Number
                                                            </label>
                                                            <Field
                                                                id="input-phone-number"
                                                                placeholder="9874563210"
                                                                name="phoneNo2"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.phoneNo2 && touched.phoneNo2
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="phoneNo2"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-dob">
                                                                DOB
                                                            </label>
                                                            <Field
                                                                id="input-dob"
                                                                placeholder="DOB"
                                                                name="dob"
                                                                type="date"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.dob && touched.dob
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="dob"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-ssn">
                                                                SSN
                                                            </label>
                                                            <Field
                                                                id="input-ssn"
                                                                placeholder="SSN"
                                                                name="ssn"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.ssn && touched.ssn
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="ssn"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <h6 className="heading-small text-muted mb-4">Home Address</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Address Line 1
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                placeholder="Address"
                                                                rows="4"
                                                                name="address1"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.address1 && touched.address1
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="address1"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Address Line 2
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                placeholder="Address"
                                                                rows="4"
                                                                name="address2"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.address2 && touched.address2
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="address2"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-city">
                                                                City
                                                            </label>
                                                            <Field
                                                                id="input-city"
                                                                placeholder="City"
                                                                name="city"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.city && touched.city
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="city"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-state">
                                                                State
                                                            </label>
                                                            <Field
                                                                id="input-state"
                                                                placeholder="State"
                                                                name="state"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.state && touched.state
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="state"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-pinCode"
                                                            >
                                                                Zip code
                                                            </label>
                                                            <Field
                                                                id="input-pinCode"
                                                                placeholder="Zip code"
                                                                name="pinCode"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.pinCode && touched.pinCode
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="pinCode"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-residingYear"
                                                            >
                                                                Time of Residency (in Years)
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                name="residingYear"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.residingYear && touched.residingYear
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option defaultValue value="" disabled>
                                                                    Residing Since
                                                                </option>
                                                                <option value="less than 1 year">
                                                                    Less Than 1 Year
                                                                </option>
                                                                <option value="2 year">2 Year</option>
                                                                <option value="3 year">3 Year</option>
                                                                <option value="4 year">4 Year</option>
                                                                <option value="5 year">5 Year</option>
                                                                <option value="6 year">6 Year</option>
                                                                <option value="7 year">7 Year</option>
                                                                <option value="more than 7 year">
                                                                    More than 7 Year
                                                                </option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="residingYear"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <h6 className="heading-small text-muted mb-4">Bank Detail</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-bankname"
                                                            >
                                                                Bank Name
                                                            </label>
                                                            <Field
                                                                id="input-bankName"
                                                                placeholder="Bank Name"
                                                                name="bankName"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.bankName && touched.bankName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="bankName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-accountType"
                                                            >
                                                                Type of Account
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                name="accountType"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.accountType && touched.accountType
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option defaultValue value="" disabled>
                                                                    Select Account Type
                                                                </option>
                                                                <option value="saving">Saving Account</option>
                                                                <option value="checking">Checking Account</option>
                                                                <option value="virtual">Virtual Account</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="accountType"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-accountNo"
                                                            >
                                                                Account Number
                                                            </label>
                                                            <Field
                                                                id="input-accountNo"
                                                                placeholder="Account Number"
                                                                name="accountNo"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.accountNo && touched.accountNo
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="accountNo"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-routingNo"
                                                            >
                                                                Routing Number
                                                            </label>
                                                            <Field
                                                                id="input-routingNo"
                                                                placeholder="Routing Number"
                                                                name="routingNo"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.routingNo && touched.routingNo
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="routingNo"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Address
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                placeholder="Address"
                                                                rows="4"
                                                                name="bankAddress"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.bankAddress && touched.bankAddress
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="bankAddress"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <h6 className="heading-small text-muted mb-4">Loan Detail</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-initialAmount"
                                                            >
                                                                Initial Amount
                                                            </label>
                                                            <Field
                                                                id="input-initialAmount"
                                                                placeholder="Initial Amount"
                                                                name="initialAmount"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.initialAmount && touched.initialAmount
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="initialAmount"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-months"
                                                            >
                                                                Months
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                name="months"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.months && touched.months
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option defaultValue value="" disabled>
                                                                    Select Month
                                                                </option>
                                                                <option value="12">12 Months [1 Year]</option>
                                                                <option value="24">24 Months [2 Year]</option>
                                                                <option value="36">36 Months [3 Year]</option>
                                                                <option value="48">48 Months [4 Year]</option>
                                                                <option value="60">60 Months [5 Year]</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="months"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-repaymentMonth"
                                                            >
                                                                Repayment Per Month
                                                            </label>
                                                            <Field
                                                                id="input-bankname"
                                                                placeholder="Repayment per Month"
                                                                name="repaymentMonth"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.repaymentMonth && touched.repaymentMonth
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="repaymentMonth"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-bankname"
                                                            >
                                                                Loan Type
                                                            </label>

                                                            <Field
                                                                as="select"
                                                                name="loanType"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.loanType && touched.loanType
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option defaultValue value="" disabled>
                                                                    Select Loan Type
                                                                </option>
                                                                <option value="personal">Personal Loan</option>
                                                                <option value="payday">Payday Loan</option>
                                                                <option value="medical">Medical Loan</option>
                                                                <option value="business">Business Loan</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="loanType"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-isPastLoan"
                                                            >
                                                                Have you taken any loan in past ?
                                                            </label>
                                                            <div onChange={(e) => onHandleChange(e)}>
                                                                <input
                                                                    type="radio"
                                                                    value="Yes"
                                                                    name="isPastLoan"
                                                                    checked={pastLoanHere}
                                                                />{' '}
                                                                Yes &nbsp;
                                                                <input
                                                                    type="radio"
                                                                    value="No"
                                                                    name="isPastLoan"
                                                                    checked={!pastLoanHere}
                                                                />{' '}
                                                                No
                                                            </div>
                                                            <ErrorMessage
                                                                name="isPastLoan"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-isPendingLoan"
                                                            >
                                                                Do you have any pending loan ?
                                                            </label>
                                                            <div onChange={(e) => onHandleChange(e)}>
                                                                <input
                                                                    type="radio"
                                                                    value="Yes"
                                                                    name="isPendingLoan"
                                                                    checked={pendingLoanHere}
                                                                />{' '}
                                                                Yes &nbsp;
                                                                <input
                                                                    type="radio"
                                                                    value="No"
                                                                    name="isPendingLoan"
                                                                    checked={!pendingLoanHere}
                                                                />{' '}
                                                                No
                                                            </div>
                                                            <ErrorMessage
                                                                name="isPendingLoan"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-isPendingBills"
                                                            >
                                                                Do you have any credit cards, Medical bills pending ?
                                                            </label>
                                                            <div onChange={(e) => onHandleChange(e)}>
                                                                <input
                                                                    type="radio"
                                                                    value="Yes"
                                                                    name="isPendingBills"
                                                                    checked={pendingBillHere}
                                                                />{' '}
                                                                Yes &nbsp;
                                                                <input
                                                                    type="radio"
                                                                    value="No"
                                                                    name="isPendingBills"
                                                                    checked={!pendingBillHere}
                                                                />{' '}
                                                                No
                                                            </div>
                                                            <ErrorMessage
                                                                name="isPendingBills"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <h6 className="heading-small text-muted mb-4">Documents</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customFileLang"
                                                            >
                                                                Upload State ID or Driving Licence Front
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    name="licenceFront"
                                                                    type="file"
                                                                    className="custom-file-input file-input"
                                                                    id="customFileLang"
                                                                    lang="en"
                                                                    onChange={(e) => getFrontImage(e)}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="customFileLang"
                                                                ></label>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {frontBase64 && (
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <img
                                                                            width="115"
                                                                            height="100"
                                                                            src={frontBase64}
                                                                            alt=""
                                                                        />
                                                                    </Col>
                                                                    <Col md="12" className="mt-2">
                                                                        {!props.match.path.endsWith('create') && (
                                                                            <>
                                                                                <Button
                                                                                    onClick={(e) =>
                                                                                        downloadImage(e, frontBase64)
                                                                                    }
                                                                                    className="text-black"
                                                                                >
                                                                                    <i className="fas fa-download text-info"></i>
                                                                                </Button>
                                                                                {(frontStatus === '2' ||
                                                                                    frontStatus === '0') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'frontLicenceStatus',
                                                                                                '1'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-check text-success"></i>
                                                                                    </Button>
                                                                                )}
                                                                                {(frontStatus === '2' ||
                                                                                    frontStatus === '1') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'frontLicenceStatus',
                                                                                                '0'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-times text-danger"></i>
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customFileLang"
                                                            >
                                                                Upload State ID or Driving Licence Back
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    name="licenceBack"
                                                                    type="file"
                                                                    className="custom-file-input file-input"
                                                                    id="customFileLang"
                                                                    lang="en"
                                                                    onChange={(e) => getBackImage(e)}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="customFileLang"
                                                                ></label>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {backBase64 && (
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <img
                                                                            width="115"
                                                                            height="100"
                                                                            src={backBase64}
                                                                            alt=""
                                                                        />
                                                                    </Col>
                                                                    <Col md="12" className="mt-2">
                                                                        {!props.match.path.endsWith('create') && (
                                                                            <>
                                                                                <Button
                                                                                    onClick={(e) =>
                                                                                        downloadImage(e, backBase64)
                                                                                    }
                                                                                    className="text-black"
                                                                                >
                                                                                    <i className="fas fa-download text-info"></i>
                                                                                </Button>
                                                                                {(backStatus === '2' ||
                                                                                    backStatus === '0') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'backLicenceStatus',
                                                                                                '1'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-check text-success"></i>
                                                                                    </Button>
                                                                                )}
                                                                                {(backStatus === '2' ||
                                                                                    backStatus === '1') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'backLicenceStatus',
                                                                                                '0'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-times text-danger"></i>
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customFileLang"
                                                            >
                                                                Address Proof
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    name="addressProof"
                                                                    type="file"
                                                                    className="custom-file-input file-input"
                                                                    id="customFileLang"
                                                                    lang="en"
                                                                    onChange={(e) => getAddressImage(e)}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="customFileLang"
                                                                ></label>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {addressBase64 && (
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <img
                                                                            width="115"
                                                                            height="100"
                                                                            src={addressBase64}
                                                                            alt=""
                                                                        />
                                                                    </Col>
                                                                    <Col md="12" className="mt-2">
                                                                        {!props.match.path.endsWith('create') && (
                                                                            <>
                                                                                <Button
                                                                                    onClick={(e) =>
                                                                                        downloadImage(e, addressBase64)
                                                                                    }
                                                                                    className="text-black"
                                                                                >
                                                                                    <i className="fas fa-download text-info"></i>
                                                                                </Button>
                                                                                {(addressStatus === '2' ||
                                                                                    addressStatus === '0') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'addressProofStatus',
                                                                                                '1'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-check text-success"></i>
                                                                                    </Button>
                                                                                )}
                                                                                {(addressStatus === '2' ||
                                                                                    addressStatus === '1') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'addressProofStatus',
                                                                                                '0'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-times text-danger"></i>
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customFileLang"
                                                            >
                                                                Selfie
                                                            </label>
                                                            <div className="custom-file">
                                                                <input
                                                                    name="selfie"
                                                                    type="file"
                                                                    className="custom-file-input file-input"
                                                                    id="customFileLang"
                                                                    lang="en"
                                                                    onChange={(e) => getSelfieImage(e)}
                                                                />
                                                                <label
                                                                    className="custom-file-label"
                                                                    htmlFor="customFileLang"
                                                                ></label>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {selfieBase64 && (
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col>
                                                                        <img
                                                                            width="115"
                                                                            height="100"
                                                                            src={selfieBase64}
                                                                            alt=""
                                                                        />
                                                                    </Col>
                                                                    <Col md="12" className="mt-2">
                                                                        {!props.match.path.endsWith('create') && (
                                                                            <>
                                                                                <Button
                                                                                    onClick={(e) =>
                                                                                        downloadImage(e, selfieBase64)
                                                                                    }
                                                                                    className="text-black"
                                                                                >
                                                                                    <i className="fas fa-download text-info"></i>
                                                                                </Button>
                                                                                {(selfieStatus === '2' ||
                                                                                    selfieStatus === '0') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'selfieStatus',
                                                                                                '1'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-check text-success"></i>
                                                                                    </Button>
                                                                                )}
                                                                                {(selfieStatus === '2' ||
                                                                                    selfieStatus === '1') && (
                                                                                    <Button
                                                                                        onClick={(e) =>
                                                                                            handleDocStatus(
                                                                                                e,
                                                                                                'selfieStatus',
                                                                                                '0'
                                                                                            )
                                                                                        }
                                                                                        className="text-black"
                                                                                    >
                                                                                        <i className="fas fa-times text-danger"></i>
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Reason For Reject Loan
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                placeholder="Reason For Reject Loan"
                                                                rows="4"
                                                                name="rejectReason"
                                                                className={'form-control-alternative form-control'}
                                                            />
                                                            {/* <ErrorMessage
                                name="rejectReason"
                                component="div"
                                className="is-invalid invalid-feedback"
                              /> */}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-confirm-password"
                                                            >
                                                                Status
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                name="loanStatus"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.loanStatus && touched.loanStatus
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option>Select</option>
                                                                <option value="2">Pending</option>
                                                                <option value="1">Approve</option>
                                                                <option value="0">Reject</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="loanStatus"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="12" className="text-right">
                                                        <Link to={'/admin/loan'}>
                                                            <Button className="mr-3 text-black">Cancel</Button>
                                                        </Link>
                                                        <Button type="submit" className="text-white btn-info">
                                                            {buttonName}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            />
        </>
    );
};

export default LoanView;

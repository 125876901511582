import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button } from 'reactstrap';

import Tables from '../../components/Tables';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
	getAllContactAPI,
	deleteContactAPI,
	deleteMultipleContactAPI,
} from '../../services/api';

const ContactUsList = () => {
	const [contactData, setContactData] = useState([]);
	const data = contactData;
	const [softDeleteShow, setSoftDeleteShow] = useState(false);
	const [hardDeleteShow, setHardDeleteShow] = useState(false);
	const [id, setID] = useState(null);
	const [message, setMessage] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const dropDownArray = [
		{ value: null, label: 'Select' },
		{ value: 'delete', label: 'Delete' },
	];

	const columns = [
		{ dataField: 'id', text: 'id', sort: true, hidden: true },
		{ dataField: 'fullName', text: 'Full Name', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{
			dataField: 'message',
			text: 'Message',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.message.substring(0, 15) + '...'}
					<a href='#!' onClick={(e) => openModal(e, row.message)}>
						Read more
					</a>
				</>
			),
		},
		{
			dataField: 'createdAt',
			text: 'Message Date',
			sort: true,
			formatter: (cell, row) => (
				<>
					{row.createdAt
						? moment.utc(row.createdAt).format('DD-MM-YYYY')
						: 'N/A'}
				</>
			),
		},
		{
			dataField: 'actions',
			text: 'Action',
			isDummyField: true,
			formatter: (cell, row) => (
				<>
					<a
						href='#!'
						className='table-action table-action-soft-delete'
						data-toggle='tooltip'
						data-original-title='Delete product'
						onClick={(e) => deleteRow(e, row.id, 'soft-delete')}>
						<i className='fas fa-trash'></i>
					</a>
					<a
						href='#!'
						className='table-action table-action-hard-delete'
						data-toggle='tooltip'
						data-original-title='Delete product'
						onClick={(e) => deleteRow(e, row.id, 'hard-delete')}>
						<i className='fas fa-trash'></i>
					</a>
					<Link
						to={`/admin/contact-us/view-details/${row.id}`}
						className='table-action table-action-toggle'
						data-toggle='tooltip'
						data-original-title='View Details'>
						<i className='fas fa-eye'></i>
					</Link>
				</>
			),
		},
	];

	const openModal = (e, message) => {
		e.preventDefault();
		setShowModal(true);
		setMessage(message);
	};

	const deleteRow = (e, id, type = '') => {
		setID(id);
		e.preventDefault();
		if (type === 'soft-delete') {
			SoftDeleteSweetAlert();
			setSoftDeleteShow(true);
		} else {
			setHardDeleteShow(true);
			HardDeleteSweetAlert();
		}
	};

	const SoftDeleteSweetAlert = () => {
		return (
			<>
				<SweetAlert
					warning
					showCancel
					confirmBtnText='Yes, trash it!'
					confirmBtnBsStyle='danger'
					cancelBtnBsStyle='default'
					title='Are you sure?'
					show={softDeleteShow}
					onCancel={() => setSoftDeleteShow(false)}
					onConfirm={() => deleteContact('soft-delete')}></SweetAlert>
			</>
		);
	};

	const HardDeleteSweetAlert = () => {
		return (
			<>
				<SweetAlert
					warning
					showCancel
					confirmBtnText='Yes, permanently delete it!'
					confirmBtnBsStyle='danger'
					cancelBtnBsStyle='default'
					title='Are you sure?'
					show={hardDeleteShow}
					onCancel={() => setHardDeleteShow(false)}
					onConfirm={() => deleteContact('hard-delete')}></SweetAlert>
			</>
		);
	};

	const deleteContact = (type) => {
		deleteContactAPI(id, type).then((result) => {
			if (result) {
				type === 'hard-delete'
					? setHardDeleteShow(false)
					: setSoftDeleteShow(false);
				toast.success('Record deleted successfully.');
				getAllContact();
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	const getAllContact = () => {
		getAllContactAPI().then((data) => {
			setContactData(data);
		});
	};

	useEffect(() => {
		getAllContact();
	}, []);

	const updateMultipleContactStatus = (selectedRow, selectionType) => {
		deleteMultipleContactAPI({
			IDArray: selectedRow,
			status: selectionType,
		}).then((result) => {
			if (result) {
				setSoftDeleteShow(false);
				toast.success('Status updated successfully.');
				getAllContact();
			} else {
				toast.error('Something went wrong.');
			}
		});
	};

	return (
		<>
			<Breadcrumbs breadcrumbName='Contact Us' />
			<SoftDeleteSweetAlert />
			<HardDeleteSweetAlert />
			<Modal className='modal-dialog-centered' isOpen={showModal}>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						Message
					</h5>
					<button
						aria-label='Close'
						className='close'
						data-dismiss='modal'
						type='button'
						onClick={() => setShowModal(false)}>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className='modal-body'>{message}</div>
				<div className='modal-footer'>
					<Button
						color='info'
						data-dismiss='modal'
						type='button'
						onClick={() => setShowModal(false)}>
						Close
					</Button>
				</div>
			</Modal>
			<Tables
				data={data}
				columns={columns}
				defaultSortKey='createdAt'
				defaultSortDirection='desc'
				selectDropDownArray={dropDownArray}
				tableTitle='Contact Inquiries List'
				updateMultipleStatus={(selectedRow, selectionType) =>
					updateMultipleContactStatus(selectedRow, selectionType)
				}
			/>
		</>
	);
};

export default ContactUsList;

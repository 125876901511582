import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// reactstrap components
import { Card, CardHeader, CardBody, FormGroup, Container, Row, Col, Button } from 'reactstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import { toast } from 'react-toastify';
import { createUserAPI, updateUserAPI, getUserByIdAPI } from '../../services/api';
import { useHistory } from 'react-router-dom';
import service from 'services/constants';

const UserView = (props) => {
    const titleName = useState(props.match.path.endsWith('create') ? 'Create User' : 'Update User');
    const buttonName = useState(props.match.path.endsWith('create') ? 'Submit' : 'Update');
    const [userDetail, setUserDetails] = useState({});
    const [base64, setBase64] = useState(null);
    const [image, setImage] = useState(null);
    const history = useHistory();
    const formFields = {
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNo1: '',
        address1: '',
        email: '',
        password: '',
        confirmPassword: '',
        image: '',
        userStatus: '',
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        middleName: Yup.string().required('Middle Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phoneNo1: Yup.string().min(10).required('Phone number is required'),
        address1: Yup.string().required('Address is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        userStatus: Yup.string().required('Please select status'),
    });

    const getImage = (e) => {
        const file = e.target.files[0];
        const allowedType = ['image/png', 'image/jpeg', 'image/jpg'];
        const type = file && e.target.files[0].type;

        if (file.size > 2097152) {
            toast.error('File size exceeds maximum limit 2 MB.');
            e.target.value = '';
            setBase64(null);
            return;
        }
        if (file && !allowedType.includes(type)) {
            toast.error('Only .jpg, .jpeg, .png image formats are allowed.');
            e.target.value = '';
            setBase64(null);
            return;
        }
        displayImage(file);
        setImage(file);
    };

    const displayImage = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    var addUser = (data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('image', image);

        createUserAPI(formData).then((result) => {
            if (result.result) {
                toast.success('Record added successfully.');
                history.push('/admin/user');
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    var updateUser = (data) => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('image', image);
        updateUserAPI(props.match.params.id, formData).then((result) => {
            if (result) {
                toast.success('Record updated successfully.');
                history.push('/admin/user');
            } else {
                toast.error('Something went wrong.');
            }
        });
    };

    useEffect(() => {
        if (!props.match.path.endsWith('create')) {
            getUserByIdAPI(props.match.params.id).then((user) => {
                setUserDetails(user);
                setBase64(user && user.image && service.API_URL + '/' + user.image);
            });
        }
    }, []);

    return (
        <>
            <Breadcrumbs breadcrumbName="User Management" />
            {/* Page content */}
            <Formik
                onSubmit={(values, actions) => {
                    if (props.match.path.endsWith('create')) {
                        addUser(values);
                    } else {
                        updateUser(values);
                    }
                }}
                initialValues={!props.match.path.endsWith('create') ? userDetail : formFields}
                enableReinitialize
                validationSchema={!props.match.path.endsWith('create') ? '' : validationSchema}
                render={({ handleSubmit, errors, touched }) => (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">{titleName}</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                First name
                                                            </label>
                                                            <Field
                                                                name="firstName"
                                                                type="text"
                                                                id="input-first-name"
                                                                placeholder="First name"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.firstName && touched.firstName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="firstName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-middle-name"
                                                            >
                                                                Middle Name
                                                            </label>
                                                            <Field
                                                                id="input-middle-name"
                                                                placeholder="Middle Name"
                                                                name="middleName"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.middleName && touched.middleName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="middleName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Last name
                                                            </label>
                                                            <Field
                                                                id="input-last-name"
                                                                placeholder="Last name"
                                                                name="lastName"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.lastName && touched.lastName
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="lastName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-phone-number"
                                                            >
                                                                Phone Number
                                                            </label>
                                                            <Field
                                                                id="input-phone-number"
                                                                placeholder="9874563210"
                                                                name="phoneNo1"
                                                                type="text"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.phoneNo1 && touched.phoneNo1
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="phoneNo1"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Address Line 1
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                placeholder="Address"
                                                                rows="4"
                                                                name="address1"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.address1 && touched.address1
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="address1"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-email">
                                                                Email
                                                            </label>
                                                            <Field
                                                                id="input-email"
                                                                placeholder="johndoe@example.com"
                                                                type="email"
                                                                name="email"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.email && touched.email
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="email"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6" hidden={!props.match.path.endsWith('create')}>
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-password"
                                                            >
                                                                Password
                                                            </label>
                                                            <Field
                                                                id="input-password"
                                                                placeholder="Password"
                                                                type="password"
                                                                name="password"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.password &&
                                                                    touched.password &&
                                                                    props.match.path.endsWith('create')
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                hidden={!props.match.path.endsWith('create')}
                                                                name="password"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6" hidden={!props.match.path.endsWith('create')}>
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-confirm-password"
                                                            >
                                                                Confirm password
                                                            </label>
                                                            <Field
                                                                id="input-confirm-password"
                                                                placeholder="Confirm password"
                                                                type="password"
                                                                name="confirmPassword"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.confirmPassword &&
                                                                    touched.confirmPassword &&
                                                                    props.match.path.endsWith('create')
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                hidden={!props.match.path.endsWith('create')}
                                                                name="confirmPassword"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col lg='12'>
														<FormGroup>
															<label
																className='form-control-label'
																htmlFor='customFileLang'>
																Image
															</label>
															<div className='custom-file'>
																<input
																	type='file'
																	className='custom-file-input file-input'
																	id='customFileLang'
																	lang='en'
																	onChange={(e) => getImage(e)}
																/>
																<label
																	className='custom-file-label'
																	htmlFor='customFileLang'></label>
															</div>
														</FormGroup>
													</Col>
													{base64 && (
														<Col lg='12'>
															<FormGroup>
																<img
																	width='100'
																	height='100'
																	src={base64}
																	alt=''
																/>
															</FormGroup>
														</Col>
													)} */}
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-confirm-password"
                                                            >
                                                                Status
                                                            </label>
                                                            <Field
                                                                as="select"
                                                                name="userStatus"
                                                                className={
                                                                    'form-control-alternative form-control' +
                                                                    (errors.userStatus && touched.userStatus
                                                                        ? ' error-box is-invalid'
                                                                        : '')
                                                                }
                                                            >
                                                                <option>Select</option>
                                                                <option value="0">Inactive</option>
                                                                <option value="1">Active</option>
                                                            </Field>
                                                            <ErrorMessage
                                                                name="userStatus"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="12" className="text-right">
                                                        <Link to={'/admin/user'}>
                                                            <Button className="mr-3 text-black">Cancel</Button>
                                                        </Link>
                                                        <Button type="submit" className="text-white btn-info">
                                                            {buttonName}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            />
        </>
    );
};

export default UserView;

import Dashboard from './views/Dashboard';
import Profile from './views/Profile.js';
import Login from './views/Login.js';
import UserList from './views/User/UserList';
import UserView from './views/User/UserView';
import LoanList from './views/Loan/LoanList';
import LoanView from './views/Loan/LoanView';
import WalletList from './views/Wallet/WalletList';
import WalletView from './views/Wallet/WalletView';
import ContactUsList from './views/ContactUs/ContactUsList';
import ContactUsView from './views/ContactUs/ContactUsView';
import LinkBankDetails from './views/LinkBankDetails';
import AboutUs from './views/AboutUs';

var routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: 'fas fa-tv text-green',
        component: Dashboard,
        layout: '/admin',
    },
    /* user */
    {
        path: '/user/create',
        component: UserView,
        layout: '/admin',
    },
    {
        path: '/user/edit/:id',
        component: UserView,
        layout: '/admin',
    },
    {
        path: '/user',
        name: 'User Management',
        icon: 'fas fa-address-card text-primary',
        component: UserList,
        layout: '/admin',
    },
    /* loan */
    {
        path: '/link-bank-details/:userId/:loanId',
        component: LinkBankDetails,
        layout: '/admin',
    },
    {
        path: '/loan/create',
        component: LoanView,
        layout: '/admin',
    },
    {
        path: '/loan/edit/:id',
        component: LoanView,
        layout: '/admin',
    },
    {
        path: '/loan',
        name: 'Loan Management',
        icon: 'fas fa-briefcase text-info',
        component: LoanList,
        layout: '/admin',
    },
    /* wallet */
    {
        path: '/wallet/create/:paymentMethod',
        component: WalletView,
        layout: '/admin',
    },
    {
        path: '/wallet/edit/:paymentMethod/:id',
        component: WalletView,
        layout: '/admin',
    },
    {
        path: '/wallet',
        name: 'Wallet',
        icon: 'fas fa-credit-card text-black',
        component: WalletList,
        layout: '/admin',
    },
    /* card */
    // {
    // 	path: '/card/create',
    // 	component: CardView,
    // 	layout: '/admin',
    // },
    // {
    // 	path: '/card/edit/:id',
    // 	component: CardView,
    // 	layout: '/admin',
    // },
    // {
    // 	path: '/card',
    // 	name: 'Card Transaction Management',
    // 	icon: 'fas fa-credit-card text-primary',
    // 	component: CardList,
    // 	layout: '/admin',
    // },
    /* User profile */
    {
        path: '/user-profile',
        name: 'Profile',
        icon: 'fas fa-user text-yellow',
        component: Profile,
        layout: '/admin',
    },
    /* contact-us */
    {
        path: '/contact-us/view-details/:id',
        component: ContactUsView,
        layout: '/admin',
    },
    {
        path: '/contact-us',
        name: 'Contact Us',
        icon: 'fas fa-envelope text-gray',
        component: ContactUsList,
        layout: '/admin',
    },
    {
        path: '/about-us/',
        name: 'About Us CMS',
        icon: 'fas fa-envelope text-gray',
        component: AboutUs,
        layout: '/admin',
    },
    /* logout */
    {
        path: '/logout',
        name: 'Logout',
        icon: 'fas fa-sign-out-alt text-red',
        layout: '/admin',
        onclick: true,
    },
    /* login */
    {
        path: '/login',
        name: 'Login',
        component: Login,
        layout: '/auth',
    },
];
export default routes;
